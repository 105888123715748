import $ from 'jquery';
import 'jquery';

$(function () {
    var shareCopy = $('#copy-share');

    shareCopy.on('click', function (e) {
        e.preventDefault();
        var link = $('#link-container').html();

        CopyToClipboard(link, true, "Copied Successfully!");
    });    

    function CopyToClipboard(value, showNotification, notificationText) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(value).select();
        document.execCommand("copy");
        $temp.remove();

        if (typeof showNotification === 'undefined') {
            showNotification = true;
        }
        if (typeof notificationText === 'undefined') {
            notificationText = "Copied to clipboard";
        }

        var notificationTag = $("<div/>", { "class": "copy-notification", text: notificationText });

        $("body").append(notificationTag);

        notificationTag.fadeIn("slow", function () {
            setTimeout(function () {
                notificationTag.fadeOut("slow", function () {
                    notificationTag.remove();
                });
            }, 3000);
        });
    }
});