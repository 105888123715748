import Page from "../../Page";
import BarChart from "./charts/BarChart";
import EssentialTaskButton from "./EssentialTaskButton";

class HandleWarPage extends Page {
	render() {
		const chartDiv = this.mountPoint.querySelector(".war-chart");
		new BarChart(chartDiv).show();
	}
}

new HandleWarPage(".organization-worker-handleWar-page").show();
new EssentialTaskButton(".war-ability-scores").render();
