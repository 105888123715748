import Chart from 'chart.js';
import ScoreColors from './ScoreColors';

export default class SinglePieChart {
	constructor(target, data) {
		this.target = target;
		this.data = data;
	}

	show() {
		const config = {
			type: 'pie',
			data: {
				labels: [
					'Pros',
					'Strengths',
					'Areas of Intervention',
					'Needs',
					'Cons',
				],
				datasets: [
					{
						data: [
							this.data['5'].count,
							this.data['4'].count,
							this.data['3'].count,
							this.data['2'].count,
							this.data['1'].count,
						],
						backgroundColor: ScoreColors.getColors().reverse(),
					},
				],
			},
		};
		const chart = new Chart(this.target, config);

		return chart;
	}
}
