import Page from "../../Page";
import $ from "jquery";
import MinMaxRangeInput from "../../../lib/MinMaxRangeInput";
import OtherSelectField from "../../../lib/OtherSelectField";
import AutoCompleteSelect from "../../../lib/AutoCompleteSelect";

class CreatePage extends Page {
	render() {
		const form = $(this.mountPoint.querySelector("form"));

		new AutoCompleteSelect(
			this.mountPoint.querySelector("form select[name=onet_soc_code]")
		);

		// Hours slider
		const hoursMin = form.find("input[name=hours_per_week_min]");
		const hoursMax = form.find("input[name=hours_per_week_max]");
		hoursMin.parent().hide();
		hoursMax.parent().hide();

		// Create a slider under description
		form.find("#description").parent().after(`<div class="form-group">
				   <label class="control-label">Hours Per Week</label>
						<div class="hours_per_week_slider"></div>
					</div>`);

		new MinMaxRangeInput(
			form.find("div.hours_per_week_slider"),
			hoursMin,
			hoursMax,
			0,
			40,
			0.5
		);

		// Wage slider
		const wageMin = form.find("input[name=wage_per_hour_min]");
		const wageMax = form.find("input[name=wage_per_hour_max]");
		wageMin.parent().hide();
		wageMax.parent().hide();

		// Create a slider under hours_per_week_max
		form.find("#hours_per_week_max").parent()
			.after(`<div class="form-group">
				   <label class="control-label">Wage Per Hour</label>
						<div class="wage_per_hour_slider"></div>
					</div>`);

		new MinMaxRangeInput(
			form.find("div.wage_per_hour_slider"),
			wageMin,
			wageMax,
			0,
			40,
			0.25
		);

		new OtherSelectField(form, "category");
	}
}

new CreatePage(".organization-job-create-page").show();
