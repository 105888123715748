import CsrfHelper from './CsrfHelper';

export default class {
	constructor(action, method, inputs = []) {
		this.action = action;
		this.method = method;
		this.inputs = inputs;
	}

	/**
	 * Returns a Form element, attached to the document body
	 * with a CSRF token input already populated
	 *
	 * @param action string The action url
	 * @param method string The method to use
	 * @param inputs Object	Additional key value pairs to add as hidden fields
	 */
	render() {
		// Create a new form element
		const form = document.createElement('form');
		form.setAttribute('action', this.action);
		form.setAttribute('method', 'POST');
		form.setAttribute('style', 'display: none;');

		// Hidden _method input
		const methodInput = document.createElement('input');
		methodInput.setAttribute('type', 'hidden');
		methodInput.setAttribute('name', '_method');
		methodInput.setAttribute('value', this.method);

		// Hidden _token input
		const token = document.createElement('input');
		token.setAttribute('type', 'hidden');
		token.setAttribute('name', '_token');
		token.setAttribute('value', CsrfHelper.getToken());

		form.appendChild(methodInput);
		form.appendChild(token);

		if (this.inputs) {
			for (var prop in this.inputs) {
				if (this.inputs.hasOwnProperty(prop)) {
					const input = document.createElement('input');
					input.setAttribute('type', 'hidden');
					input.setAttribute('name', prop);
					input.setAttribute('value', this.inputs[prop]);
					form.appendChild(input);
				}
			}
		}

		document.body.appendChild(form);

		return form;
	}
}
