import $ from 'jquery';
import Page from '../../../pages/Page';
import SchoolDistrictFields from '../../../pages/organization/SchoolDistrictFields';

class CreatePage extends Page {
	render() {
		var form = $(this.mountPoint).find('form');
		new SchoolDistrictFields(form);

		form.submit(function() {
			var submitButton = form.find('.btn-primary');
			submitButton.prop('disabled', true);
			submitButton.html('Creating');
		});
	}
}

(new CreatePage('.admin-organization-create-page')).show();
