import $ from 'jquery';
import 'select2';
import 'select2/src/scss/core.scss';

export default class AutoCompleteSelect {
	constructor(select) {
		this.select = $(select);

		/* Need to inline the width for responsive behavior */
		this.select.css('width', '100%');
		this.select.select2();
	}
}
