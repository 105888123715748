import $ from 'jquery';

export default class OtherSelectField {
	constructor(form, fieldName) {
		this.form = $(form);
		this.fieldName = fieldName;
		this.otherFieldName = `${this.fieldName}_other`;
		
		this.selectField = this.form.find(`select[name=${this.fieldName}]`);
		this.otherField = this.form.find(`input[name=${this.otherFieldName}]`);

		this.otherField.parent().hide();

		this.selectField.on('change', () => {
			if (this.selectField.val() === this.selectField.attr('data-other-value')) {
				this.otherField.parent().show();
			} else {
				this.otherField.parent().hide();
			}
		}).trigger('change');
	}
}
