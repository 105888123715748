import Chart from 'chart.js';
import randomcolor from 'randomcolor';

export default class RadarChart {
	constructor(target) {
		this.target = target;
	}

	show() {
		const dataPlots = JSON.parse(this.target.getAttribute('data-plots'));
		const dataLabels = this.makeLabels(dataPlots);
		const dataSets = this.makeDatasets(dataPlots);

		const config = {
			type: 'radar',
			data: {
				labels: dataLabels,
				datasets: dataSets,
			},
			options: {
				title: {
					display: true,
					text: 'Rater Comparison',
					fontSize: 32,
				},

				legend: {
					display: true,
				},
			},
		};
		new Chart(this.target, config);
	}

	makeDatasets(plots) {
		var dataSets = [];
		var colors = ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink'];

		for (let assessment in plots) {
			var color = randomcolor({
				luminosity: 'bright',
				format: 'rgba',
				hue: colors[assessment],
				alpha: 1.0,
			});
			// alpha prop doesn't work for randomcolor as far as I can tell.  Color is just a string (rgba(17, 54, 219, 0.4220068824493324)), so set it ourselves.
			var colorBG = color.split(',');
			colorBG[3] = ' 0.2)';
			colorBG = colorBG.toString();

			var dataPoint = [];

			for (let cat in plots[assessment].assessment) {
				let categoryData = plots[assessment].assessment[cat];
				dataPoint.push(categoryData.percentage);
			}

			dataSets.push({
				label: plots[assessment].timestamp,
				data: dataPoint,
				backgroundColor: colorBG,
				borderColor: color,
				pointBorderColor: color,
				pointBackgroundColor: color,
				fill: true,
			});
		}

		return dataSets;
	}

	makeLabels(plots) {
		var labels = [];
		for (let category in plots[0].assessment) {
			labels.push(plots[0].assessment[category].category.name);
		}

		return labels;
	}
}