import $ from 'jquery';
import Page from "../Page";
import SchoolDistrictFields from "./SchoolDistrictFields";

class CreatePage extends Page {
	render() {
		var form = $(this.mountPoint).find('form');
		new SchoolDistrictFields(form);

		form.submit(function() {
			var submitButton = form.find('.btn-primary');
			submitButton.prop('disabled', true);
			submitButton.html('Creating');
		});
	}
}

(new CreatePage('.organization-create-page')).show();
