import AjaxDataTable from '../../../lib/AjaxDataTable';
import Page from '../../../pages/Page';

class IndexPage extends Page {
	render() {
		(new AjaxDataTable(
			this.mountPoint.querySelector('table.table'),
			'organization/datatable',
			[
				{
					data: 'name',
				},
				{
					data: 'type',
					searchable: false,
					orderable: false,
				},
				{
					data: 'account_status',
					searchable: false,
					orderable: false,
				},
				{
					data: 'action_links',
					searchable: false,
					orderable: false,
				},
			]
		)).show();
	}
}

(new IndexPage('.admin-organization-index-page')).show();
