
export default class ScoreColors {
	static getColors() {
		return [
			'#FF0000',
			'#fb8140',
			'#FFFF00',
			'#09d796',
			'#00B050',
		];
	}
}
