import Chart from 'chart.js';
import ScoreColors from './ScoreColors';

export default class MultipleFingerprintChart {
	constructor(target, data) {
		this.target = target;
		this.data = data;
		this.colors = ScoreColors.getColors();
	}

	show() {
		const data = this.generateConfigData();
		const config = {
			type: 'horizontalBar',
			data: data,
			options: {
				scales: {
					xAxes: [{
						stacked: true,
					}],
					yAxes: [{
						stacked: true,
					}],
				},
			},
		};

		return new Chart(this.target, config);
	}

	generateConfigData() {
		return {
			labels: this.makeLabels(),
			datasets: this.makeDatasets(),
		};
	}

	makeLabels() {
		return this.data.map(function (d) {
			return d.name;
		});
	}

	makeDatasets() {
		return [
			{
				label: 'Pros',
				backgroundColor: this.colors[4],
				data: this.dataForScore('5'),
			}, {
				label: 'Strengths',
				backgroundColor: this.colors[3],
				data: this.dataForScore('4'),
			}, {
				label: 'Areas for Intervention',
				backgroundColor: this.colors[2],
				data: this.dataForScore('3'),
			}, {
				label: 'Needs',
				backgroundColor: this.colors[1],
				data: this.dataForScore('2'),
			}, {
				label: 'Cons',
				backgroundColor: this.colors[0],
				data: this.dataForScore('1'),
			},
		];
	}

	dataForScore(score) {
		return this.data.map((d) => {
			return d.scores[score].count;
		});
	}
}
