import Chart from 'chart.js';
import randomcolor from 'randomcolor';

export default class MultiBarChart {
	constructor(target) {
		this.target = target;
	}

	show() {
		const dataPlots = JSON.parse(this.target.getAttribute('data-plots'));
		const dataLabels = this.makeLabels(dataPlots);

		const config = {
			type: 'bar',
			data: {
				labels: dataLabels,
				datasets: this.makeDatasets(dataPlots),
			},
			options: {
				title: {
					display: true,
					text: 'Ability Scale by Sub-Score',
					fontSize: 32,
				},
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							autoSkip: false,
							callback: function(value) {
								return value + '%';
							},
						},
					}],
					xAxes: [{
						ticks: {
							autoSkip: false,
						},
					}],
				},
				legend: {
					display: true,
				},
			},
		};
		new Chart(this.target, config);
	}

	makeDatasets(plots) {
		var dataSets = [];

		for (let assessment in plots) {
			var color = randomcolor();
			var dataPoint = [];

			for (let cat in plots[assessment].assessment) {
				let categoryData = plots[assessment].assessment[cat];
				dataPoint.push(categoryData.percentage);
			}

			dataSets.push({
				label: plots[assessment].timestamp,
				data: dataPoint,
				backgroundColor: color,
				borderColor: color,
				fill: true,
			});
		}

		return dataSets;
	}

	makeLabels(plots) {
		var labels = [];
		for (let category in plots[plots.length - 1].assessment) {
			labels.push(plots[plots.length - 1].assessment[category].category.name);
		}

		return labels;
	}
}