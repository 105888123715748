import $ from 'jquery';

export default class SchoolDistrictFields {
	constructor(form) {
		this.form = $(form);
		this.showFields = this.form.find('.only-show-when');
		this.typeField = this.form.find('select[name=type]');
		
		this.typeField.on('change', () => {
			this.showFields.each((index, cur) => {
				const field = $(cur);
				if (this.typeField.val() === field.attr('data-show-when-value')) {
					field.parent().show();
				} else {
					field.parent().hide();
				}
			});
		}).trigger('change');
	}
}
