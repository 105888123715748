import Page from "../Page";
import AjaxDataTable from "../../lib/AjaxDataTable";
import moment from "moment";

class ShowPage extends Page {
	render() {
		const org_id = this.mountPoint.getAttribute("data-id");
		new AjaxDataTable(
			this.mountPoint.querySelector(".jmrs-table"),
			`${org_id}/datatable`,
			[
				{
					data: "worker.name",
				},
				{
					data: "site.name",
					searchable: false,
				},
				{
					data: "created_at",
					searchable: false,
					render: (data) => moment(data).format("llll"),
				},
				{
					data: "status_readable",
					searchable: false,
					orderable: false,
				},
				{
					data: "action_links",
					searchable: false,
					orderable: false,
				},
			]
		).show();
	}
}

new ShowPage(".organization-show-page").show();
