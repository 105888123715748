import Chart from 'chart.js';
import ScoreColors from './ScoreColors';

export default class SingleFingerprintChart {
	constructor(target, data) {
		this.target = target;
		this.data = data;
		this.colors = ScoreColors.getColors();
	}

	show() {
		const config = {
			type: 'horizontalBar',
			data: {
				labels: ['JMR Score'],
				datasets: [{
					label: 'Pros',
					backgroundColor: this.colors[4],
					data: [this.data['5'].count],
				}, {
					label: 'Strengths',
					backgroundColor: this.colors[3],
					data: [this.data['4'].count],
				}, {
					label: 'Areas for Intervention',
					backgroundColor: this.colors[2],
					data: [this.data['3'].count],
				}, {
					label: 'Needs',
					backgroundColor: this.colors[1],
					data: [this.data['2'].count],
				}, {
					label: 'Cons',
					backgroundColor: this.colors[0],
					data: [this.data['1'].count],
				}],
			},
			options: {
				scales: {
					xAxes: [{
						stacked: true,
					}],
					yAxes: [{
						stacked: true,
					}],
				},
			},
		};
		new Chart(this.target, config);
	}
}
