import Page from "../../Page";
import SortableTable from "../../../lib/SortableTable";

class ShowPage extends Page {
	render() {
		const job = Number.parseInt(
			this.mountPoint.getAttribute("data-job-id")
		);
		new SortableTable(
			this.mountPoint.querySelector(".essential-tasks-table"),
			`${job}/essential-task/reorder`
		);
	}
}

new ShowPage(".organization-job-show-page").show();
