import Page from "../../Page";
import RadarChart from "./charts/RadarChart";
import $ from "jquery";
import "jquery";

class HandleRarPage extends Page {
	render() {
		$(".table-category-row").on("click", function (event) {
			var id = $(event.currentTarget).attr("data-id");
			var div = $("#category-breakdown-tables");

			$("table.rar-task-table").hide();

			if (id === div.attr("data-id")) {
				div.toggle();
			} else {
				div.attr("data-id", id);
				div.show();
			}

			$(`table[data-id="${id}"]`).show();
		});

		const barChartDiv = this.mountPoint.querySelector(".rar-bar-chart");
		new RadarChart(barChartDiv).show();
	}
}

new HandleRarPage(".organization-worker-handleRar-page").show();
