import Page from "../../Page";
import $ from "jquery";
import SinglePieChart from "./SinglePieChart";
import MultipleFingerprintChart from "./MultipleFingerprintChart";

class ShowPage extends Page {
	render() {
		this.jmrPies = $(this.mountPoint).find(".jmr-pies .jmr-pie");
		this.organization_id = this.mountPoint.getAttribute(
			"data-organization-id"
		);
		this.jmr_id = this.mountPoint.getAttribute("data-jmr-id");

		const fingerprintData = this.jmrPies
			.map((i, v) => {
				return this.renderPie(v);
			})
			.get();

		this.renderFingerprintChart(fingerprintData);
	}

	renderPie(panel) {
		const canvas = panel.querySelector("canvas");
		const ja_id = panel.getAttribute("data-ja-id");
		const data = JSON.parse(canvas.getAttribute("data-fingerprint-data"));
		const chart = new SinglePieChart(canvas, data.scores).show();
		canvas.addEventListener("click", (e) => {
			const elms = chart.getElementAtEvent(e);

			if (elms.length > 0) {
				const elm = elms[0];
				const scores = [5, 4, 3, 2, 1];

				const score = scores[elm._index];

				this.openModal(ja_id, score);
			}
		});

		return data;
	}

	renderFingerprintChart(data) {
		const canvas = this.mountPoint.querySelector(".vocational-fingerprint");
		const chart = new MultipleFingerprintChart(canvas, data).show();

		canvas.addEventListener("click", (e) => {
			const elms = chart.getElementAtEvent(e);

			if (elms.length > 0) {
				const elm = elms[0];
				const ja_id = data[elm._index].id;
				const scores = [5, 4, 3, 2, 1];

				const score = scores[elm._datasetIndex];

				this.openModal(ja_id, score);
			}
		});
	}

	openModal(ja_id, score) {
		/* Open the modal */
		const modal = $(`.jmr-ja-${ja_id}-details`);
		modal.modal("show");
		modal.on("shown.bs.modal", () => {
			const view = document.querySelector(
				`#jmr-ja-${ja_id}-details-${score}`
			);
			view.scrollIntoView();
		});
	}
}

new ShowPage(".organization-jmr-show-page").show();
