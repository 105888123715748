import Page from '../../../../pages/Page';
import AutoCompleteSelect from '../../../../lib/AutoCompleteSelect';

class ShowPage extends Page {
	render() {
		new AutoCompleteSelect(this.mountPoint.querySelector('form select[name=email]'));
	}
}

(new ShowPage('.admin-organization-site-show-page')).show();
