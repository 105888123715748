import HiddenForm from './HiddenForm';
import $ from 'jquery';

$('body').on('click', 'a.delete-link', function (e) {
	e.preventDefault();
	if (confirm('Are you sure you want to delete this?')) {
		const form = new HiddenForm(
			$(this).attr('href'),
			'DELETE'
		);

		form.render().submit();
	}
});
