import $ from 'jquery';
import Page from '../../../../pages/Page';

class CreatePage extends Page {
	render() {
		var form = $(this.mountPoint).find('form');

		form.submit(function() {
			var submitButton = form.find('.btn-primary');
			submitButton.prop('disabled', true);
			submitButton.html('Adding');
		});
	}
}

(new CreatePage('.admin-organization-site-create-page')).show();
