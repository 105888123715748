import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui-touch-punch';

export default class MinMaxRangeInput {
	constructor(target, minInputField, maxInputField, minVal, maxVal, step) {
		this.target = $(target);
		this.minVal = minVal;
		this.maxVal = maxVal;
		this.step = step;

		this.minInputField = $(minInputField);
		this.maxInputField = $(maxInputField);

		// Set the current values
		const curMinVal = this.minInputField.val() || minVal;
		const curMaxVal = this.maxInputField.val() || maxVal;
		this.minInputField.val(curMinVal);
		this.maxInputField.val(curMaxVal);

		const sliderDiv = $('<div class="min-max-range-slider"></div>').appendTo(this.target);

		sliderDiv.slider({
			range: true,
			min: minVal,
			max: maxVal,
			step: step,
			values: [curMinVal, curMaxVal],
			slide: (event, ui) => {
				this.minInputField.val(ui.values[0]);
				this.maxInputField.val(ui.values[1]);
			},
		});

		// Append the styled input fields
		this.maxInputField.detach();
		this.minInputField.detach();
		this.target.append(this.minInputField);
		this.target.append(this.maxInputField);
		this.target.append('<div class="clearfix"></div>');

		this.maxInputField.css({
			float: 'right',
			width: '15%',
			margin: '10px',
			'text-align': 'center',
		});

		this.minInputField.css({
			float: 'left',
			width: '15%',
			margin: '10px',
			'text-align': 'center',
		});

		// Setup change listeners
		this.minInputField.on('change', (e) => {
			sliderDiv.slider('values', 0, ($(e.target).val()));
		});
		this.maxInputField.on('change', (e) => {
			sliderDiv.slider('values', 1, ($(e.target).val()));
		});
	}


}
