import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import AjaxHelper from "./AjaxHelper";

export default class SortableTable {
	constructor(table, endpoint, doneCallback = () => {}) {
		this.table = $(table);
		this.endpoint = endpoint;

		this.table.find('tbody').sortable({
			update: (event, ui) => {
				const order = ui.item.parent().children('tr').map((i, e) => {
					return e.getAttribute('data-id');
				}).toArray();

				new AjaxHelper(this.endpoint).put({
					objects: order,
				}).done(doneCallback);
			},
		});
	}
}
