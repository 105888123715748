import Page from '../../../pages/Page';
import SchoolDistrictFields from '../../../pages/organization/SchoolDistrictFields';

class EditPage extends Page {
	render() {
		new SchoolDistrictFields(this.mountPoint.querySelector('form'));
	}
}

(new EditPage('.admin-organization-edit-page')).show();
