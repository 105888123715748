import Page from "../../Page";
import $ from "jquery";

class IndexPage extends Page {
	render() {
		const showUnassignedUsersButton = $(
			this.mountPoint.querySelector(".show-unassigned-users-btn")
		);
		const unassignedUsersPanel = $(
			this.mountPoint.querySelector(".show-unassigned-users")
		);

		unassignedUsersPanel.hide();

		showUnassignedUsersButton.on("click", (e) => {
			e.preventDefault();
			unassignedUsersPanel.toggle();
		});
	}
}

new IndexPage(".organization-site-index-page").show();
