import Page from "../Page";
import $ from "jquery";
import "easy-autocomplete";
import "easy-autocomplete/src/sass/easy-autocomplete.scss";
import "easy-autocomplete/src/sass/easy-autocomplete.themes.scss";

class IndexPage extends Page {
	render() {
		const options = {
			url: "/invitation/search",
			getValue: "name",
			template: {},
			list: {
				match: {
					enabled: true,
				},
				onChooseEvent: function () {
					const value = $("#organization").getSelectedItemData().id;
					$("input[name=organization_id]").val(value);
				},
			},
		};
		$("#organization").easyAutocomplete(options);
	}
}

new IndexPage(".invitation-index-page").show();
