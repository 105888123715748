import Page from "../../Page";
import $ from "jquery";
import FingerprintData from "./FingerprintData";
import SingleFingerprintChart from "./SingleFingerprintChart";

class EditPage extends Page {
	render() {
		this.fingerprints = $(this.mountPoint).find(
			".fingerprints > .fingerprint"
		);
		this.organization_id = this.mountPoint.getAttribute(
			"data-organization-id"
		);
		this.jmr_id = this.mountPoint.getAttribute("data-jmr-id");
		this.fingerprintData = new FingerprintData(
			this.organization_id,
			this.jmr_id
		);

		this.fingerprints.each((i, v) => {
			this.setupChangeListener(v);
		});
	}

	setupChangeListener(fingerprint) {
		const ja_select = $(fingerprint).find("select.ja_select").first();
		ja_select
			.on("change", () => {
				const ja_id = ja_select.val();

				if (ja_id) {
					this.fingerprintData.forAssessment(ja_id).then((data) => {
						this.drawFingerprintChart(fingerprint, data);
					});
				} else {
					this.showInvalidJobAssessment(fingerprint);
				}
			})
			.trigger("change");
	}

	showInvalidJobAssessment(fingerprint) {
		$(fingerprint)
			.find(".panel-body")
			.html("<p>No valid VFA-Js for this Job.</p>");
		this.setMagnitude(fingerprint, 0);
	}

	drawFingerprintChart(fingerprint, data) {
		// add the canvas element
		const chartCanvas = $(
			'<canvas class="jmr-chart" height="100"></canvas>'
		);

		$(fingerprint).find(".panel-body > p.chart-content").html(chartCanvas);
		new SingleFingerprintChart(chartCanvas, data.scores).show();

		const scores = ["1", "2", "3", "4", "5"].map((elm) => {
			return data.scores[elm];
		});

		const totalPoints = scores.reduce((total, elm) => {
			return total + elm.count;
		}, 0);

		const weightedMagintudes = scores.reduce((total, elm) => {
			return total + elm.count * elm.magnitude;
		}, 0);

		const avgMagnitude = weightedMagintudes / totalPoints;

		this.setMagnitude(fingerprint, avgMagnitude);
	}

	setMagnitude(fingerprint, magnitude) {
		const current_magnitude = fingerprint.getAttribute("data-magnitude");

		if (Number(current_magnitude) !== Number(magnitude)) {
			fingerprint.setAttribute("data-magnitude", magnitude);
			this.handleMagnitudeChange();
		}
	}

	handleMagnitudeChange() {
		this.fingerprints.sort((a, b) => {
			return (
				Number(a.getAttribute("data-magnitude")) -
				Number(b.getAttribute("data-magnitude"))
			);
		});

		$(this.mountPoint).find(".fingerprints").html(this.fingerprints);
	}
}

new EditPage(".organization-jmr-edit-page").show();
