import $ from 'jquery';
import CsrfHelper from './CsrfHelper';

export default class {
	constructor(url, options = {}) {
		this.url = url;
		this.options = options;
	}

	request(method, data) {
		const defaults = {
			url: this.url,
			method: method,
			headers: {
				'X-CSRF-TOKEN': CsrfHelper.getToken(),
			},
			data: data,
		};

		return $.ajax($.extend({}, defaults, this.options));
	}

	put(data) {
		return this.request('PUT', data);
	}

	post(data) {
		return this.request('POST', data);
	}

	get(data) {
		return this.request('GET', data);
	}
}
