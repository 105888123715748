import $ from 'jquery';
import Page from "../../Page";

class IndexPage extends Page {
	render() {
		if ($('#hide_djb').is(':checked')) {
			$('label[for=hide_djb]').html('Hide DJB Jobs');
		}
		$('#hide_djb').change(function() {
			if($(this).is(':checked')) {
				$('label[for=hide_djb]').html('Hide DJB Jobs');
			} else {
				$('label[for=hide_djb]').html('Show DJB Jobs');
			}
		});
	}
}

(new IndexPage('.organization-job-index-page')).show();
