import Page from "../Page";
import SchoolDistrictFields from "./SchoolDistrictFields";

class EditPage extends Page {
	render() {
		new SchoolDistrictFields(this.mountPoint.querySelector("form"));
	}
}

new EditPage(".organization-edit-page").show();
