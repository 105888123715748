import AjaxHelper from "../../../lib/AjaxHelper";

export default class FingerprintData {
	constructor(organization_id, jmr_id) {
		this.organization_id = organization_id;
		this.jmr_id = jmr_id;
	}

	forAssessment(ja_id) {
		return new AjaxHelper(
			`/organization/${this.organization_id}/jmr/${this.jmr_id}/fingerprint`
		).get({ job_assessment_id: ja_id });
	}
}
