import Chart from 'chart.js';
import $ from 'jquery';

export default class BarChart {
	constructor(target) {
		this.target = target;
	}

	show() {
		const dataPlots = JSON.parse(this.target.getAttribute('data-plots'));

		// cast to array for sorting
		var dataPlots2 = Object.keys(dataPlots).map(function (key) {
			return dataPlots[key];
		});
		// Sorting the plots in descending order
		dataPlots2 = dataPlots2.sort((a, b) => (parseInt(a.percentage, 10) < parseInt(b.percentage, 10)) ? 1 : -1);
		const dataLabels = this.makeLabels(dataPlots2);

		const config = {
			type: 'bar',
			data: {
				labels: dataLabels,
				datasets: this.makeDataset(dataPlots2),
			},
			options: {
				title: {
					display: true,
					text: 'Ability Scale by Subscore (Percentage) ',
					fontSize: 32,
				},
				scales: {
					yAxes: [{
						scaleLabel: {
							display: true,
							labelString: 'Percentage (%)',
						},
						ticks: {

							beginAtZero: true,
							autoSkip: false,
						},
					}],
					xAxes: [{
						ticks: {
							autoSkip: false,
						},
					}],
				},
				legend: {
					display: false,
				},
				onClick: (event) => {
					if (thisChart.getElementsAtEvent(event)[0]) {
						this.showTable(thisChart.getElementsAtEvent(event)[0]._view.label);
					}
				},
			},
		};
		var thisChart = new Chart(this.target, config);
	}

	makeDataset(plots) {
		var data = [];
		var colors = [
			'#040644',
			'#080B7B',
			'#282C9E',
			'#3F43B7',
			'#6264CC',
			'#7F82DC',
			'#9B9DE6',
			'#BEBFEF',
			'#E2E3FB',
			'#EFEFFB',
			'#F3F3F9',
		];

		for (let category in plots) {
			data.push(plots[category].percentage);
		}

		return [{
			label: 'abilities',
			data: data,
			backgroundColor: colors,
			borderColor: 'rgba(0, 0, 0, 0.1)',
			borderWidth: 2,
			fill: true,
		}];
	}

	makeLabels(plots) {
		var labels = [];
		for (let category in plots) {
			labels.push(plots[category].category.name);
		}

		return labels;
	}

	showTable(category) {
		if (category) {
			var ele = $('.war-ability-table');
			let eleCategory = ele.attr('data-category');

			if (eleCategory === category) {
				// toggle the visibility on clicking the same bar again
				ele.toggle();
			} else {
				// toggle and display the generated table.
				ele.attr('data-category', category);

				let table = $('.war-ability-table > tbody');
				table.find(`tr[data-category="${category}"]`).show();
				table.find(`tr[data-category!="${category}"]`).hide();
				ele.show();
			}
		}
	}
}
