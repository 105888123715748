import Page from "../../Page";
import LineChart from "./charts/LineChart";
import MultiBarChart from "./charts/MultiBarChart";
import $ from "jquery";

class HandleWaotPage extends Page {
	render() {
		$(".table-category-row").on("click", function (event) {
			var id = $(event.currentTarget).attr("data-id");
			var div = $("#category-breakdown-tables");

			$("table.rar-task-table").hide();

			if (id === div.attr("data-id")) {
				div.toggle();
			} else {
				div.attr("data-id", id);
				div.show();
			}

			$(`table[data-id="${id}"]`).show();
		});

		const lineChartDiv = this.mountPoint.querySelector(".waot-line-chart");
		const barChartDiv = this.mountPoint.querySelector(".waot-bar-chart");
		new LineChart(lineChartDiv).show();
		new MultiBarChart(barChartDiv).show();
	}
}

new HandleWaotPage(".organization-worker-handleWaot-page").show();
