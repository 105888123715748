import Page from "../../../Page";
import $ from "jquery";
import OtherSelectField from "../../../../lib/OtherSelectField";

class EditPage extends Page {
	render() {
		const form = $(this.mountPoint.querySelector("form"));

		new OtherSelectField(form, "transition_team_role");
		new OtherSelectField(form, "place_of_employment");
	}
}

new EditPage(".organization-worker-worker-assessment-edit-page").show();
