import AjaxHelper from "../../../lib/AjaxHelper";
import $ from "jquery";

export default class EssentialTaskButton {
	constructor(target) {
		this.target = target;
	}

	render() {
		let buttons = $(".ability-table-btn");
		let table = $(".war-ability-table");
		let orgid = table.attr("data-orgid");
		let workerid = table.attr("data-workerid");
		let parent = this;

		buttons.click(function () {
			var row = $(this).closest("tr").first();

			let content = {
				worker_id: workerid,
				task_id: row.attr("data-taskid"),
				is_essential: row.attr("data-critical") == "false",
			};

			new AjaxHelper(
				`/organization/${orgid}/worker/${workerid}/essential-task/toggle-worker`
			)
				.request("POST", content)
				.done(function (response) {
					var res = JSON.parse(response);
					var ele = $(
						`.ability-table-btn[data-taskid=${row.attr(
							"data-taskid"
						)}]`
					);
					if (res.status == "DELETED") {
						row.attr("data-critical", "false");

						if (ele.find("i").hasClass("fas")) {
							ele.html('<i class="far fa-star"></i>');
						}
					} else if (res.status == "CREATED") {
						row.attr("data-critical", "true");

						if (ele.find("i").hasClass("far")) {
							ele.html('<i class="fas fa-star"></i>');
						}
					}

					parent.sortEssentialInTable();
				});
			//.fail(function (e) {});
		});
	}

	sortEssentialInTable() {
		var rows = $(".war-ability-table tbody tr").get();
		rows.sort(function (a, b) {
			var ax = a.getAttribute("data-critical") == "true";
			var bx = b.getAttribute("data-critical") == "true";
			var ay = a.getAttribute("data-score");
			var by = b.getAttribute("data-score");
			var az = $(a).children("td").first().html();
			var bz = $(b).children("td").first().html();

			// Essential Sorting
			if (ax == false && bx == true) {
				return 1;
			} else if (ax == true && bx == false) {
				return -1;
			}

			// Score Sorting
			if (ay > by) {
				return 1;
			} else if (ay < by) {
				return -1;
			}

			// Name Sorting
			if (az > bz) {
				return 1;
			} else if (az < bz) {
				return -1;
			}

			return 0;
		});

		$.each(rows, function (index, row) {
			$(".war-ability-table tbody").append(row);
		});
	}
}
