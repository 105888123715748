import Page from "../../Page";
import $ from "jquery";
import AjaxHelper from "../../../lib/AjaxHelper";
import AutoCompleteSelect from "../../../lib/AutoCompleteSelect";

class CreatePage extends Page {
	constructor(mountPoint) {
		super(mountPoint);

		this.workerSelect = $(this.mountPoint)
			.find("form select[name=worker_id]")
			.first();
		this.assessmentSelect = $(this.mountPoint)
			.find("form select[name=worker_assessment_id]")
			.first();
	}

	render() {
		new AutoCompleteSelect(this.workerSelect);

		this.workerSelect
			.on("change", () => {
				this.refreshAssessmentSelect();
			})
			.trigger("change");
	}

	refreshAssessmentSelect() {
		this.assessmentSelect.parent().hide();

		const org = this.mountPoint.getAttribute("data-organization-id");
		const worker = this.workerSelect.val();

		// Run ajax call for assessments
		new AjaxHelper(`/organization/${org}/worker/${worker}/assessments`)
			.get({})
			.then((data) => {
				this.assessmentSelect.empty();

				data.forEach((v) => {
					this.assessmentSelect.append(
						`<option value='${v.id}'>${v.name}</option>`
					);
				});

				new AutoCompleteSelect(this.assessmentSelect);
				this.assessmentSelect.parent().show();
			});
	}
}

new CreatePage(".organization-jmr-create-page").show();
