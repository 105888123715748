import Chart from 'chart.js';
import randomcolor from 'randomcolor';

export default class LineChart {
	constructor(target) {
		this.target = target;
	}

	show() {
		const dates = JSON.parse(this.target.getAttribute('data-dates'));
		const dataPlots = JSON.parse(this.target.getAttribute('data-plots'));
		const config = {
			type: 'line',
			data: {
				labels: dates,
				datasets: this.makeDatasets(dataPlots),
			},
			options: {
				title: {
					display: true,
					text: 'Ability Scale Over Time',
					fontSize: 32,
				},
				scales: {
					yAxes: [
						{
							stacked: true,
							ticks: {
								beginAtZero: true,
								max: 100,
								callback: function(value) {
									return value + '%';
								},
							},
						},
					],
				},
			},
		};
		new Chart(this.target, config);
	}

	makeDatasets(plots) {
		return plots.map((plot) => {
			const color = randomcolor();
			return {
				label: plot.name,
				data: plot.data,
				borderColor: color,
				backgroundColor: color,
				fill: true,
			};
		});
	}
}
