/**
 * Parent class for Pages
 *
 * Override render() at the least to describe page specific js.
 *
 * By default shouldRender() returns true if the mountPoint given in
 * the constructor is not null. You may need to override this.
 */
export default class Page {
	constructor(mountPointSelector) {
		this.setMountPointBySelector(mountPointSelector);
	}
	
	setMountPoint(mountPoint) {
		this.mountPoint = mountPoint;
	}

	setMountPointBySelector(mountPointSelector) {
		this.mountPoint = document.querySelector(mountPointSelector);
	}

	shouldRender() {
		return this.mountPoint !== null;
	}

	render() {
		// do nothing by default
	}

	show() {
		if (this.shouldRender()) {
			this.render();
		}
	}
}
