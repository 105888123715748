import AjaxDataTable from '../../../lib/AjaxDataTable';
import Page from '../../../pages/Page';

class IndexPage extends Page {
	render() {
		(new AjaxDataTable(
			this.mountPoint.querySelector('table.table'),
			'user/datatable',
			[
				{
					data: 'first_name',
				},
				{
					data: 'last_name',
				},
				{
					data: 'email',
				},
				{
					data: 'action_links',
					searchable: false,
					orderable: false,
				},
			]
		)).show();

		var forceUpdate = document.getElementById('force-update');
		forceUpdate.addEventListener('click', function (e) {
			var response = confirm('Are you sure you would like to force a profile update?');

			if (response) {
				return true;
			} else {
				e.preventDefault();
			}
		});
	}
}

(new IndexPage('.admin-user-index-page')).show();
