import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-bs/css/dataTables.bootstrap.css';

export default class AjaxDataTable {
	constructor(target, endpoint, columns) {
		this.target = $(target);
		this.endpoint = endpoint;
		this.columns = columns;
	}

	show() {
		this.target.DataTable({
			processing: true,
			serverSide: true,
			ajax: this.endpoint,
			columns: this.columns,
		});
	}
}

